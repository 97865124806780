.header-navbar-container{
    position: fixed;
    width: 100%;
    z-index: 10;
}

.navbar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 88px;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
    align-items: center;
    top:0;
    padding: 40px;
}

.side-bar-inactive{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    padding: 40px;
    z-index: 9999999;
    background: #6755CE;
    width:278px;
    height: 600px;
    top:0;
    z-index: 2;
    right: -278px;
    position: fixed;
    transition: transform .5s;

}
.nav-links{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.Header-navlink {
    margin-left: 20px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #6755CE;
  }
  
  .header-user-name> p{
      display: inline-block;
  }
  .Header-navlink:hover {
    color: var(--secondary-blue);
  }
  
  .Header-navlink-selected {
    color: var(--primary-blue);
  }
  .pozi-cards{
      font-weight: 700;
  }
  .header-info-container{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-content: flex-end;
  }
  .hamburger-menu{
      margin-left: 35%;
      display: none;
  }
  .Header-user {
    display: flex;
    align-items: center;
  }
  
  .Header-user img {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 6px;
  }
  .side-bar{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align: left;
      padding: 40px;
      z-index: 9999999;
      background: #6755CE;
      width:278px;
      height: 600px;
      top:0;
      z-index: 2;
      right: -278px;
      position: fixed;
      transition: transform .5s;
      transform: translateX(-100%);
  }
  .side-bar-container{
      display: flex;
      flex-direction: column;
  }
  .side-bar-header{
      height: 50px;
      align-items: flex-start;
  }
  .sidebar-profile-pic{
      width: 20px;
      height: 20px;

  }
  .sidebar-content{
      display: flex;
      flex-direction: column;
      color: rgb(29, 27, 27) !important;
      text-decoration: none;
  }
  .myuser-firstname{
      color:white
  }
  .sidebar-navlink{
      color:  #FFFFFF !important;
  }
  .sidebar-user-name{
      color:white !important;
  }
  .navbar-main{
      display: flex;
      flex-direction: row;

  }
  .sidebar-user-links{
      display: flex;
      flex-direction: column;
      gap:16px;
  }
  .sidebar-token{
      display: flex;
      flex-direction: row;
      align-content: center;

  }
  .sidebar-notification{
        text-decoration: none;
        color: white !important;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .sidebar-notification:hover{
        text-decoration: none;
    }
    .sidebar-message{
        display: flex;
        flex-direction: row;
        align-items: center;
    }   
  .side-route-links{
      display: flex;
      flex-direction:column ;
      gap:16px;
      color: wheat;
      font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF;
        margin-top:20px;
  }
  .sidebar-icons{
      filter:invert(1);
      width:24px;
      height: 24px;
  }
  .sidebar-text{
      margin-left: 6px;
  }
  .header-user-info{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap:30px;
  }
  .header-alerts{
      display: flex;
      flex-direction: row;
      gap:16px;
  }
  .close-sidebar{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
  }
  .side-logout{
      cursor: pointer;
  }
  .sidebar-logout{
      color: white;
      cursor: pointer;
      display: flex;
      gap:5px;
  }
  .sidebar-login{
    color: white;
  }
  .close-icon{
      width: 24px;
      height: 24px;
      filter:invert(1);
  }
  .sidebar-user-links{
      display: flex;
      flex-direction: column;
      color: white;
  }
  .Login-route{
    font-weight: 700;
    color: #6755ce;
  }
  .hey{
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 28px;
    text-align: right;
    color: #262626;
    margin: 0;
    
  }
  .hey span{
    color: #6755ce !important;
  }
  .header-token{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 28px;
        color: #262626;
  }
  .header-token-img{
      margin-right: 5px;
  }
  .logout-icon > svg:nth-child(1) {
    width: 25px;
    height: 25px;
    cursor: pointer;
   }
   .logout-icon {
    display: flex;
    align-content: center;
   }
    
  @media (max-width: 1360px) {
    
    .hamburger-menu{
        display: inline-block;
    }
   
    .Header-navlink{
        display: none;
    }
    
    .header-user-info{
        justify-content: flex-end
    }
    .header-user-info{
        display: none;
    }
    .username-container{
        display: flex;
        gap:5px;
        align-items: center;
    }
  }
  @media (max-width: 1360px) {
      .sidebar-user{
          display: none;
      }
      .Header-user-name{
          display: none;
      }
      .Login-route{
          display: none;
      }
  }
  @media (min-width: 1360px) {
   .side-bar{
       display: none;
   }
}
